<template>
  <div>
    <div class="filter-container">
      <el-form inline>
        <el-form-item>
          <el-input v-model="query.nickname" placeholder="请输入用户昵称" clearable />
        </el-form-item>
        <el-form-item>
          <el-input v-model="query.cellphone" placeholder="请输入手机号" clearable />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="queryTime.createTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="申请开始时间"
            end-placeholder="申请结束时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="change"
          />
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.type" placeholder="请选择认证类型" clearable>
            <el-option v-for="(value, key) in type" :key="key" :value="key" :label="value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.auditStatus" placeholder="请选择审核状态" clearable>
            <el-option v-for="(value, key) in audit" :key="key" :value="key" :label="value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          <el-button type="danger" icon="el-icon-refresh" @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list">
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="nickname"
        label="用户昵称"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="cellphone"
        label="手机号码"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="headUrl"
        label="用户头像"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          <img :src="row.headUrl" alt="" style="width:60px;height:60px;border-radius:50%;">
        </template>
      </el-table-column>
      <el-table-column
        prop="authIdCardStatus"
        label="实名认证"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ authIdCardStatus[row.authIdCardStatus] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="申请时间"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="type"
        label="认证类型"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ type[row.type] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="checkTime"
        label="审核时间"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="rechargeStatus"
        label="审核状态"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ audit[row.auditStatus] }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="300px"
      >
        <template slot-scope="{ row }">
          <el-row>
            <el-button v-if="row.auditStatus === 1" type="danger" size="small" @click="examineFail(row.id)">审核不通过</el-button>
            <el-button v-else type="success" size="small" @click="examineSuccess(row.id)">审核通过</el-button>
            <el-button type="primary" size="small" @click="detail(row.id)">详情</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-container">
      <el-pagination
        background
        :total="count"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :current-page.sync="page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="refresh"
        @current-change="(val) => {
          page = val
          getList()
        }"
      />
    </div>
    <!-- 详情 -->
    <Form ref="form" />
  </div>
</template>

<script>
import list from '@/mixins/list'
import * as api from '@/api/check'
import Form from './form'
export default {
  components: { Form },
  mixins: [list],
  data() {
    return {
      api,
      type: {
        1: '身份认证',
        2: '工作认证',
        3: '学历认证'
      },
      authIdCardStatus: {
        0: '未认证',
        1: '认证中',
        2: '认证成功',
        3: '认证失败'
      },
      audit: {
        0: '待审核',
        1: '审核通过',
        2: '审核不通过'
      },
      createTime: [],
      arrivalCreateTime: [],
      examineShow: false,
      examineId: '',
      auditStatus: ''
    }
  },
  methods: {
    change(val) {
      if (val) {
        this.query.startTime = val[0]
        this.query.endTime = val[1]
      } else {
        this.query.startTime = ''
        this.query.endTime = ''
      }
    },
    examineSuccess(id) {
      this.api.examineSuccess(id).then(() => {
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.getList()
      })
    },
    examineFail(id) {
      this.api.examineFail(id).then(() => {
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.getList()
      })
    }
  }
}
</script>
